<template>
  <div>
    <vx-card title="Comptes clients">
      <div slot="no-body">
        <div class="p-8">
          <div class="pt-2 pb-4">
            <div class="pt-2 pb-2">
              <vs-collapse>
                <vs-collapse-item class="aab-vie-collapse-item">
                  <div slot="header">
                    <div class="aab-vie-collapse-filter">Filtrage</div>
                  </div>
                  <div class="p-3">
                    <vs-row vs-w="12" vs-align="center">
                      <vs-col
                        class="pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="ebsi-collapse-content w-full p-1">
                          <span class="pl-2">Contrat</span>
                          <multiselect
                            placeholder="--Choisir--"
                            v-model="crrStatus"
                            label="label"
                            track-by="label"
                            :options="allStatusFilter"
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                            ><span slot="no-options">
                              {{ global.selectNotFoundData }}
                            </span></multiselect
                          >
                        </div>
                      </vs-col>
                    </vs-row>
                    <vs-row vs-w="12" vs-align="center" vs-justify="center">
                      <vs-col
                        class="pt-4 pb-2"
                        vs-type="flex"
                        vs-align="center"
                        vs-justify="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <vs-button @click="resetFilter">
                          Réinitialiser
                        </vs-button>
                      </vs-col>
                    </vs-row>
                  </div>
                </vs-collapse-item>
              </vs-collapse>
            </div>
          </div>
          <vs-prompt
            title="Exportation"
            class="export-options"
            @cancel="clearFields"
            @accept="exportToData"
            accept-text="Valider"
            cancel-text="Annuler"
            @close="clearFields"
            :active.sync="activePrompt"
          >
            <vs-input
              v-model="fileName"
              placeholder="Entrez le nom du fichier..."
              class="w-full"
            />
            <multiselect
              v-model="selectedFormat"
              :options="formats"
              class="my-4"
              placeholder="--Choisir--"
              :selectLabel="global.selectLabel"
              :selectedLabel="global.selectedLabel"
              :deselectLabel="global.deselectLabel"
            >
              <template v-slot:noOptions>
                <span>{{ global.selectNotFoundData }}</span>
              </template>
            </multiselect>

            <div class="flex">
              <span class="mr-4">Largeur automatique de cellule:</span>
              <vs-switch v-model="cellAutoWidth"
                >Largeur automatique de cellule</vs-switch
              >
            </div>
          </vs-prompt>

          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          >
            <div>
              <div>
                <vs-dropdown class="dd-actions cursor-pointer">
                  <div
                    class="
                      p-3
                      shadow-drop
                      rounded-lg
                      d-theme-dark-bg
                      cursor-pointer
                      flex
                      items-center
                      justify-center
                      text-lg
                      font-medium
                      w-32 w-full
                    "
                  >
                    <span class="mr-2">Afficher {{ limit }}</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item
                      :key="i"
                      v-for="(r, i) in rowsTable"
                      @click="limit = r"
                    >
                      <span class="flex items-center">
                        <span>{{ r }}</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </div>
            <div>
              <vs-dropdown class="dd-actions cursor-pointer ml-4">
                <div
                  class="
                    p-3
                    shadow-drop
                    rounded-lg
                    d-theme-dark-bg
                    cursor-pointer
                    flex
                    items-center
                    justify-center
                    text-lg
                    font-medium
                    w-32 w-full
                  "
                >
                  <span class="mr-2">Actions</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="activePrompt = true">
                    <span class="flex items-center">
                      <feather-icon
                        icon="PrinterIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>Exporter</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    v-if="checkUserRole('customer_account').delete"
                    :disabled="accountSelected.length == 0"
                    @click="checkRemoveMultipleAccount"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="Trash2Icon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>Supprimer la sélection</span>
                    </span>
                  </vs-dropdown-item>
                  <vx-tooltip
                    color="primary"
                    :title="role.notAllowTitle"
                    :text="role.notAllowText"
                    v-else
                  >
                    <vs-dropdown-item :disabled="true">
                      <span class="flex items-center">
                        <feather-icon
                          icon="Trash2Icon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Supprimer la sélection</span>
                      </span>
                    </vs-dropdown-item>
                  </vx-tooltip>
                  <vs-dropdown-item
                    v-if="checkUserRole('broker').send"
                    :disabled="accountSelected.length == 0"
                    @click="checkSentPublipostage"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="MailIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>Publipostage</span>
                    </span>
                  </vs-dropdown-item>
                  <vx-tooltip
                    color="primary"
                    :title="role.notAllowTitle"
                    :text="role.notAllowText"
                    v-else
                  >
                    <vs-dropdown-item :disabled="true">
                      <span class="flex items-center">
                        <feather-icon
                          icon="MailIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Publipostage</span>
                      </span>
                    </vs-dropdown-item>
                  </vx-tooltip>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </div>

          <div class="clearfix mb-3 mt-6">
            <div class>
              <div class="w-auto" v-if="loadingListing">
                <clip-loader
                  :loading="loadingListing"
                  color="primary"
                ></clip-loader>
              </div>
              <div v-else>
                <div v-if="allUsers.list.length !== 0">
                  <vs-table
                    pagination
                    multiple
                    :data="allUsers.list"
                    v-model="accountSelected"
                    search
                    noDataText="Aucun résultat trouvé"
                    stripe
                    :max-items="limit != 'Tout' ? limit : total"
                  >
                    <template slot="thead">
                      <vs-th>N°</vs-th>
                      <vs-th sort-key="identity">Identité</vs-th>
                      <vs-th sort-key="email">E-mail</vs-th>
                      <vs-th sort-key="username">Nom d'utilisateur</vs-th>
                      <vs-th sort-key="projet">Projet(s)</vs-th>
                      <vs-th sort-key="contrat">Contrat(s)</vs-th>
                      <vs-th>Actions</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td :data="data[indextr].id">{{
                          indextr + 1
                        }}</vs-td>

                        <vs-td :data="data[indextr].identity">{{
                          data[indextr].identity
                        }}</vs-td>
                        <vs-td :data="data[indextr].email">{{
                          data[indextr].email
                        }}</vs-td>
                        <vs-td :data="data[indextr].username">{{
                          data[indextr].username
                        }}</vs-td>
                        
                        <vs-td :data="data[indextr].projectClient">
                          <div v-if="data[indextr].projectClient">
                            {{ data[indextr].projectClient.all }}
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].projectClient">
                          <div v-if="data[indextr].projectClient">
                            {{ data[indextr].projectClient.contractsAll }}
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].id">
                          <div class="inline-flex" @click.stop>
                            <vx-tooltip
                              color="primary"
                              :title="lastLogin(data[indextr]).title"
                              :text="lastLogin(data[indextr]).text"
                            >
                              <div class="cursor-pointer">
                                <feather-icon
                                  icon="FlagIcon"
                                  class="text-primary"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                            </vx-tooltip>

                            <vs-dropdown class="dd-actions cursor-pointer ml-6">
                              <div class="cursor-pointer">
                                <feather-icon
                                  icon="AlignJustifyIcon"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                              <vs-dropdown-menu>
                                <div>
                                  <vs-dropdown-item
                                    v-if="
                                      checkUserRole('customer_account').update
                                    "
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="checkUpdateUser(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          class="text-primary"
                                          icon="Edit2Icon"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Modifier</span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="Edit2Icon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span>Modifier</span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <vs-dropdown-item
                                    v-if="
                                      checkUserRole('customer_account').update
                                    "
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="
                                        checkInitializeUser(data[indextr])
                                      "
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          class="text-primary"
                                          icon="RefreshCcwIcon"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Réinitialiser</span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="RefreshCcwIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Réinitialiser</span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <vs-dropdown-item
                                    v-if="
                                      checkUserRole('customer_account').lock
                                    "
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="ChangeStatuts(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          :icon="
                                            tooltipIconText({
                                              status: data[indextr].status,
                                            }).icon
                                          "
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">
                                          {{
                                            tooltipIconText({
                                              status: data[indextr].status,
                                            }).text
                                          }}
                                        </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            :icon="
                                              tooltipIconText({
                                                status: data[indextr].status,
                                              }).icon
                                            "
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">
                                            {{
                                              tooltipIconText({
                                                status: data[indextr].status,
                                              }).text
                                            }}
                                          </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <vs-dropdown-item
                                    v-if="
                                      checkUserRole('customer_account').delete
                                    "
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="checkRemoveUser(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          icon="Trash2Icon"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Supprimer </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="Trash2Icon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Supprimer </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <vs-dropdown-item
                                    v-if="
                                      checkUserRole('customer_account').send
                                    "
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="sendMail(data[indextr].id)"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          icon="SendIcon"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Envoyer mail </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="SendIcon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Envoyer mail </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>
                              </vs-dropdown-menu>
                            </vs-dropdown>
                            <vx-tooltip
                              position="left"
                              color="primary"
                              :title="aboutElement(data[indextr]).title"
                              :text="aboutElement(data[indextr]).text"
                            >
                              <div class="cursor-pointer ml-6">
                                <feather-icon
                                  icon="InfoIcon"
                                  class="text-primary"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                            </vx-tooltip>
                            <div
                              class="ml-2"
                              v-show="woIsAction == data[indextr].id"
                            >
                              <clip-loader
                                :loading="
                                  loadingRemoveUser ||
                                  loadingUpdateUser ||
                                  loadingInitUser ||
                                  loadingAllowUser
                                "
                                color="primary"
                                size="25px"
                              ></clip-loader>
                            </div>
                          </div>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <vs-pagination
                    class="mt-6"
                    :total="allPages"
                    v-model="currentPage"
                  ></vs-pagination>
                </div>
                <div v-else>
                  <div class="flex items-center justify-center mt-8">
                    <strong>Aucun enregistrement</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <vs-popup
            class="holamundo"
            title="Modifier un Client"
            :active.sync="popupUpdateUserActive"
          >
            <div class="m-4">
              <div class="clearfix">
                <div>
                  <vs-row vs-w="12">
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Entrer votre nom"
                          label="Nom*"
                          v-model="formUpdated.lastname"
                          v-validate="'required|min:3'"
                          data-vv-validate-on="blur"
                          name="nom"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("nom") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Entrer votre prénom(s)"
                          label="Prénom(s)*"
                          v-model="formUpdated.firstname"
                          v-validate="'required|min:3'"
                          data-vv-validate-on="blur"
                          name="prénom"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("prénom") }}
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Entrer votre email"
                          label="Email*"
                          v-model="formUpdated.email"
                          v-validate="'required|email'"
                          data-vv-validate-on="blur"
                          type="email"
                          name="email"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("email") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Entrer votre Téléphone"
                          label="Téléphone*"
                          v-model="formUpdated.phone"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="Téléphone"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("Téléphone") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          placeholder="Entrer votre Adresse"
                          label="Adresse*"
                          v-model="formUpdated.addres"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="Adresse"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("Adresse") }}
                        </div>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <ul class="centerx flex">
                          <li class="pr-2 w-full">
                            <vs-radio v-model="formUpdated.gender" vs-value="M"
                              >Masculin</vs-radio
                            >
                          </li>
                          <li class="w-full">
                            <vs-radio v-model="formUpdated.gender" vs-value="F"
                              >Feminin</vs-radio
                            >
                          </li>
                        </ul>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
              <div class="mt-8">
                <div class="inline-flex">
                  <div>
                    <vs-button
                      class="w-full sm:w-auto"
                      :disabled="!validateFormUpdateUser || loadingUpdateUser"
                      @click="fetchApplyUpdateUser"
                      >Valider</vs-button
                    >
                    <vs-button
                      class="w-full sm:w-auto ml-4"
                      type="border"
                      @click="popupUpdateUserActive = false"
                      >Annuler</vs-button
                    >
                  </div>
                  <div class="ml-2">
                    <clip-loader
                      :loading="loadingUpdateUser"
                      color="primary"
                    ></clip-loader>
                  </div>
                </div>
                <div class="mt-6">
                  <p class="text-danger">* Champ obligatoire</p>
                </div>
              </div>
            </div>
          </vs-popup>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      formUpdated: {
        email: "",
        firstname: "",
        lastname: "",
        gender: "",
        phone: "",
        addres: "",
        type: "",
      },
      crrStatus: "",
      activePrompt: false,
      isResetFilter: false,
      allStatusFilter: [
        { id: "all", label: "Tout" },
        { id: "active", label: "Contrat actif" },
        { id: "pendingInactive", label: "Contrat Bientôt inactif" },
        { id: "inactive", label: "Contrat inactif" },
      ],
      formats: ["xlsx", "csv", "txt"], //formats: ["xlsx", "csv", "txt", "pdf"],
      headerTitle: ["N°", "Nom", "Prénom", "Nom d'utilisateur", "Email"],
      headerVal: ["index", "lastname", "firstname", "username", "email"],
      selectedFormat: "xlsx",

      crrAccount: "",
      woIsAction: "",
      accountSelected: [],
      crrIDs: [],

      usernameInit: "",
      allowStatus: "",
      fileName: "",

      adminTypeUpdated: "",
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      //Pagination
      type: "client",
      popupUpdateUserActive: false,
      loadingAllowUser: false,
      loadingListing: false,
      loadingRemoveUser: false,
      loadingInitUser: false,
      loading: false,
      loadingUpdateUser: false,
      error: false,
      cellAutoWidth: true,

      typeList: [
        { id: "administrator_sys", label: "Administrateur" },
        { id: "gestionnaire_sys", label: "Gestionnnaire" },
        { id: "medical_advisor_sys", label: "Médecin-Conseil" },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "logout"]),
    ...mapGetters("users", ["allUsers"]),
    ...mapGetters("general", ["rowsTable"]),
    validateFormUpdateUser() {
      return (
        !this.errors.any() &&
        this.formUpdated.email != "" &&
        this.formUpdated.firstname != "" &&
        this.formUpdated.gender != "" &&
        this.formUpdated.phone != "" &&
        this.formUpdated.addres != "" &&
        this.formUpdated.type != "" &&
        this.formUpdated.lastname != ""
      );
    },
  },
  created() {
    this.role = role;
    this.global = global;
    Validator.localize("fr", VeeValidateFR);
    this.limit = this.rowsTable[1];
  },
  mounted() {
    if (
      this.user.type != "super_administrator_sys" &&
      !this.user.person.userAcces.chk_customer_client
    ) {
      if (!this.checkUserRole("customer_account").all)
        this.$router.push({ name: "Home" });
    }
  },
  components: {
    ClipLoader,
    Multiselect,
  },
  methods: {
    ...mapActions("users", [
      "applyGetAllUsersSys",
      "applyPutUserSys",
      "applyRemoveUserSys",
      "applyAllowUserSys",
      "applyInitializeUserSys",
    ]),
    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.allUsers.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            type: this.type,
            firstname: el.person.firstname,
            lastname: el.person.lastname,
            username: el.username,
            email: el.email,
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES CLIENTS"
        );
      } else {
        let tableToPrint = [];
        this.allUsers.list.forEach((el) => {
          let jsonTmp = {
            type: this.type,
            lastname: el.person.lastname,
            firstname: el.person.firstname,
            username: el.username,
            email: el.email,
            sexe: el.person.gender,
            addres: el.person.addres,
            phone: el.person.phone,
            infos: this.aboutElement(el).text,
            project: el.projectClient.all,    
            contrat: el.projectClient.active + el.projectClient.pendingInactive + el.projectClient.inactive,
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = [
          "type",
          "firstname",
          "lastname",
          "username",
          "email",
          "phone",
          "sexe",
          "addres",
          "project",
          "contrat",
          "infos",
        ];

        let headerTitleTmp = [
          "Profil",
          "Prénom(s)",
          "Nom",
          "Nom d'utilisateur (N° client)",
          "Email",
          "Téléphone",
          "Sexe",
          "Adresse",
          "Projet",
          "Contrat",
          "Informations",
        ];

        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },
    clearFields() {
      (this.filename = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    dateElement(data) {
      return mixin.methods.dateElement(data);
    },
    resetFilter() {
      this.isResetFilter = true;
      this.crrStatus = "";
      this.isResetFilter = false;

      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        type: this.type,
        startDate: this.startDate,
        endDate: this.endDate,
        intermediary: this.intermediary.id ? this.intermediary.id : "",
        projectStatus: this.projectStatus.id ? this.projectStatus.id : "",
      });
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    tooltipIconText(data) {
      // "Activation / Désactivation"
      if (Object.keys(data) == "status") {
        return data.status === "inactive"
          ? { text: "Activation", icon: "LockIcon" }
          : { text: "Désactivation", icon: "UnlockIcon" };
      } else if (Object.keys(data) == "mask") {
        return data.isVisible
          ? { text: "Masquer", icon: "EyeOffIcon" }
          : { text: "Démasquer", icon: "EyeIcon" };
      }
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },
    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    checkUpdateUser(item) {
      this.crrID = item.id;
      this.formUpdated = { ...item.person, ...item };
      this.popupUpdateUserActive = true;
    },
    async fetchApplyUpdateUser() {
      if (!this.validateFormUpdateUser) return false;
      let data = {
        userId: this.crrID,
        ...this.formUpdated,
      };
      console.log("donn:", data);
      this.loadingUpdateUser = true;
      try {
        await this.applyPutUserSys(data);
        this.acceptAlert(
          mixin.methods.message("customer_account", "update").success
        );

        this.formUpdated = {};
        this.loadingUpdateUser = false;
        this.popupUpdateUserActive = false;
      } catch (err) {
        this.loadingUpdateUser = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupUpdateUserActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("customer_account", "update").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    checkInitializeUser(item) {
      this.crrID = item.id;
      this.usernameInit = item.username;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment initaliser le mot de passe de ${item.person.lastname} ${item.person.firstname}?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyInitializeUser,
      });
    },
    async fetchApplyInitializeUser() {
      this.woIsAction = this.crrID;
      let data = {
        username: this.usernameInit,
      };

      this.loadingInitUser = true;
      try {
        await this.applyInitializeUserSys(data);
        this.loadingInitUser = false;
        this.acceptAlert(
          mixin.methods.message("customer_account", "reinitialise").success
        );

        this.woIsAction = "";
      } catch (err) {
        this.loadingInitUser = false;
        this.woIsAction = "";
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Mot de passe",
                    "Administrateur n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("customer_account", "reinitialise")
                      .error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    ChangeStatuts(item) {
      this.crrID = item.id;
      let text = "";

      if (item.status === "inactive") {
        this.allowStatus = "active";
        text = `Voulez-vous vraiment activer ${item.person.lastname} ${item.person.firstname}?`;
      } else {
        this.allowStatus = "inactive";
        text = `Voulez-vous vraiment désactiver ${item.person.lastname} ${item.person.firstname}?`;
      }
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyAllowUser,
      });
    },
    async fetchApplyAllowUser() {
      this.woIsAction = this.crrID;
      let data = {
        userId: this.crrID,
        status: this.allowStatus,
      };
      let text = {};
      if (this.allowStatus === "inactive") {
        text = mixin.methods.message("customer_account", "desactive").success;
      } else {
        text = mixin.methods.message("customer_account", "active").success;
      }

      this.loadingAllowUser = true;
      try {
        await this.applyAllowUserSys(data);
        this.loadingAllowUser = false;
        this.acceptAlert(text);
        this.woIsAction = "";
      } catch (err) {
        this.loadingAllowUser = false;
        this.woIsAction = "";
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Administrateur n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("customer_account", "update").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    checkSentPublipostage() {
      let emailList = [];
      this.accountSelected.forEach((user) => {
        emailList.push(user.email);
      });

      this.$router.push({
        name: "Mailing",
        query: { name: "users", data: emailList },
      });
    },
    checkRemoveMultipleAccount() {
      this.accountSelected.forEach((element) => {
        this.crrIDs.push(element.id);
      });
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer les éléments sélectionnés ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchRemoveMultipleAccounts,
      });
    },
    async fetchRemoveMultipleAccounts() {
      let data = {
        ids: this.crrIDs,
      };

      this.loading = true;
      try {
        await this.applyRemoveUserSys(data);
        this.loading = false;
        this.acceptAlert(
          mixin.methods.message("customer_account", "multipleDeletion").success
        );

        this.crrIDs = [];
        this.projectSelected = [];
      } catch (err) {
        this.loading = false;
        this.crrIDs = [];
        this.projectSelected = [];

        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    mixin.methods.message(
                      "customer_account",
                      "multipleDeletion"
                    ).error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    checkRemoveUser(user) {
      this.crrID = user.id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer ${user.person.lastname} ${user.person.firstname}?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRemoveUser,
      });
    },
    async fetchApplyRemoveUser() {
      this.woIsAction = this.crrID;
      let data = {
        ids: [this.crrID],
      };
      this.loadingRemoveUser = true;

      try {
        await this.applyRemoveUserSys(data);
        this.loadingRemoveUser = false;
        this.acceptAlert(
          mixin.methods.message("customer_account", "delete").success
        );
        this.woIsAction = "";
        this.accountSelected = [];
      } catch (err) {
        this.loadingRemoveUser = false;
        this.woIsAction = "";
        this.accountSelected = [];
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Suppression",
                    "Administrateur n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("customer_account", "delete").error
                  );
                  break;
              }
            });
          }
        }
      }
    },
    sendMail(item) {
      console.log(item);
      setTimeout(() => {
        this.$router.push({
          name: "MailReceived",
          params: { userId: item, crrModuleName: "customerAccount" },
        });
      }, 100);
    },
    async fetchApplyGetAllUsersSys(data) {
      this.loadingListing = true;
      try {
        await this.applyGetAllUsersSys(data);
        this.total = this.allUsers.total;
        let p = Math.ceil(this.total / this.limit);
        this.allPages = p ? p : 1;

        this.loadingListing = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loadingListing = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Administrateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Administrateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
  },

  watch: {
    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;

      this.fetchApplyGetAllUsersSys({
        limit: this.limit,
        skip: this.skip,
        project: this.crrStatus != undefined ? this.crrStatus.id : "",
        type: this.type,
      });
    },
    limit() {
      this.skip = 0;

      this.fetchApplyGetAllUsersSys({
        limit: this.limit,
        skip: this.skip,
        project: this.crrStatus != undefined ? this.crrStatus.id : "",
        type: this.type,
      });
    },
    crrStatus() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllUsersSys({
          limit: this.limit,
          skip: this.skip,
          project: this.crrStatus != undefined ? this.crrStatus.id : "",
          type: this.type,
        });
      }
    },
  },
};
</script>
